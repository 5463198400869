import React from "react";
import NewBanner from './components/banner/NewBanner'

function App() {
  return (
    <>
      
      <NewBanner/>
    </>
  );
}

export default App;
