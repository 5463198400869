import React from "react"
import { useDispatch, useSelector } from "react-redux";

import { MetamaskConnection } from '../../redux/connectWallet/action'

const Connect = () => {
    const dispatch = useDispatch();
    const contractData = useSelector((state) => state.contract);
    return (
        <>
            <button className="btn-connect" onClick={() => {
                dispatch(MetamaskConnection())
            }}
            disabled = {contractData.signerWallet !== "" ? true : false}
            >
                {contractData.signerWallet !== "" ? contractData.signerWallet.slice(0,4) + "....." + contractData.signerWallet.slice(38,43) : 'Connect'}
            </button>

        </>
    )
}

export default Connect